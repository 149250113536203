/* eslint-disable  react/no-unescaped-entities, no-script-url */
import React from 'react';
import Tile from '../components/_generic/tile/Tile';
import TileBody from '../components/_generic/tile/TileBody';
import DashboardLayout from '../layouts/DashboardLayout';
import { getServerSideEssentials } from '../redux/actions/ssr';
import { wrapper } from '../redux/store/store';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container">
        <Tile>
          <TileBody nobackground>
            <div className="position-relative">
              <h1 style={{ color: '#5A266A' }}>Privacy Policy</h1>
              <p>
                Here at 5pm(<b>we, us</b>), we are committed to protecting the
                data that we hold and use about you and to respecting your
                privacy. We are confident that you will find the information
                that you need set out in this policy, but if you need to know
                more about the data that we hold or the way that we use it, you
                can contact us by emailing{' '}
                <a href="mailto:privacy@5pm.co.uk">privacy@5pm.co.uk</a>.
              </p>
              <ol>
                <li>
                  <i>About this policy</i>
                </li>

                <li>
                  <i>The way that we use your personal data</i>
                </li>

                <ol type="a">
                  <li>
                    <i>What personal data do we collect?</i>
                  </li>

                  <li>
                    <i>How do we collect personal data?</i>
                  </li>

                  <li>
                    <i>How do we use personal data?</i>
                  </li>

                  <li>
                    <i>How do we share your personal data?</i>
                  </li>
                </ol>

                <li>
                  <i>Security and retention of your personal data</i>
                </li>

                <li>
                  <i>Your rights</i>
                </li>

                <li>
                  <i>Cookie policy</i>
                </li>

                <li>
                  <i>Privacy policy of other websites</i>
                </li>

                <li>
                  <i>Changes to our privacy policy</i>
                </li>

                <li>
                  <i>How to contact us</i>
                </li>

                <li>
                  <i>How to contact the appropriate authority</i>
                </li>
              </ol>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                1. About this policy
              </h2>
              <p>
                This policy (together with our Terms of Use, E-commerce terms
                and other documents that we may refer to within this policy or
                within the Terms of Use or E-commerce terms) describes the data
                that we collect from you or about you, and the way that we use
                it. Please read the policy carefully as it will help you to
                understand the way that we use your data and our reasons for
                doing so.
              </p>
              <p>
                The "data controller" of your personal data (in other words, the
                organisation that determines how your data is used) is Wowcher
                Limited trading as 5pm (company number 04264984) of Wowcher
                Limited, Unit 1-2, 19A Fortess Grove, London, England, NW5 2HD{' '}
              </p>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                2. The way that we use your personal data
              </h2>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                A. What personal data do we collect?
              </h3>
              <p>
                This policy gives you information about the way that we use your
                "personal data". Personal data is any information that could be
                used to identify you in some way. The personal data that we
                collect from or about you may include the following:
              </p>
              <ul>
                <li>name;</li>

                <li>address;</li>

                <li>e-mail address; </li>

                <li>phone number;</li>

                <li>
                  any location or category preferences you have subscribed to in
                  relation to the products and services we offer;
                </li>

                <li>
                  location information from the device that you use to visit the
                  site or whilst using our App;{' '}
                </li>

                <li>
                  financial, credit and debit card information when you make a
                  purchase or call our customer service line;
                </li>

                <li>
                  information about the products and services you purchase from
                  us;
                </li>

                <li>
                  information about your visit to our websites, including
                  information about how and when you came to visit our websites,
                  how you interacted with the websites (products you viewed or
                  searched for; page response times, download errors, length of
                  visits to certain pages, page interaction information such as
                  scrolling, clicks, and mouse-overs), and where you went next
                  (including full URLs and methods used to browse away from the
                  site);
                </li>

                <li>
                  technical information about the way that you use web or mobile
                  services, including the internet protocol (IP) address used to
                  connect your computer to the internet, your login information
                  if you register on our website or via our app, browser type
                  and version, time zone setting, browser plug-in types and
                  versions, operating system and platform, device information;
                </li>

                <li>
                  information collected from our websites and elsewhere across
                  the internet through cookies, web beacons, pixel tags, device
                  identifiers and other technologies including information about
                  your shopping habits and preferences; and{' '}
                </li>

                <li>
                  demographic information and other information provided by you.
                </li>
              </ul>
              <p>
                Although we may offer items or services for purchase which may
                be relevant to children, we do not intend for any of our sites
                or services to be used by children, and we do not intend to
                collect data from children.
              </p>
              <p>
                Similarly, we do not intend to collect (and we ask you not to
                disclose) any particularly sensitive data such as data relating
                to your health, religion or ethnic background.
              </p>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                B. How do we collect personal data?
              </h3>
              <b>Directly from you:</b>
              <p>
                You directly provide us with most of the data we collect. We
                collect data and process data when you:{' '}
              </p>
              <ul>
                <li>register on our site as a customer or make an enquiry;</li>

                <li>purchase any of our products or services;</li>

                <li>
                  participate in our message boards blogs or any other user
                  generated content facilities;
                </li>

                <li>send emails to us;</li>

                <li>
                  use or view our website via your browser&#39;s cookies;{' '}
                </li>

                <li>visit our premises; </li>

                <li>
                  collect a Click and Collect order from our premises; and{' '}
                </li>

                <li>
                  undergo new business checks as part of our new
                  business/merchant onboarding process.{' '}
                </li>

                <li>
                  send the message <q>subscribe</q>to our WhatsApp Marketing
                  Channel. In doing this you are agreeing for us to store and
                  process your name & mobile phone number associated to your
                  WhatsApp account. This information will be shared with our
                  partner Vonage in order to provide WhatsApp marketing
                  communications.
                </li>
              </ul>
              <p style={{ textAlign: 'justify' }}>
                Our website uses cookies to recognise you when you visit and use
                the site. This helps us to provide you with a good experience
                when you browse the site (for example, certain cookies help to
                remember your preferences or items that you have placed in your
                'basket' on the site) and also allows us to improve the site.
                Other cookies help us to tailor online advertising based on your
                interests and behaviour on our website and across the internet.
              </p>
              <p style={{ textAlign: 'justify' }}>
                {' '}
                Certain information is collected by most browsers or
                automatically through your device, and we also collect your IP
                address or other device identifier (this enables us to recognise
                your computer or device when you use the site) via our server
                log files.
              </p>
              <p style={{ textAlign: 'justify' }}>
                If you visit one of our premises to collect a Click and Collect
                order, we will ask you for a form of identification which shows
                your full name, such as a bank card or driving licence. This is
                so that we can verify that the parcel you are collecting has
                been ordered by you.
              </p>
              <p style={{ textAlign: 'justify' }}>
                As a merchant, if you visit one of our premises, or if we visit
                you at your premises, we may process your personal data. This
                personal data may include: your name, job title, employer,
                postal address, e-mail address and phone number (including
                mobile number). We may also ask to see a form of photo
                identification to verify your identity.
              </p>
              <p style={{ textAlign: 'justify' }}>
                We conduct checks as part of our onboarding process for new
                businesses/merchants. If we are onboarding you as a new
                business/merchant, we will ask you to provide a form of photo
                identification to verify your identity. Our warehouse inspector
                will either have a video call with you to match you to your
                photo or visit your premises in person to verify the match. .
              </p>
              <b>From other sources:</b>
              <p> We may also receive your data from 3rd parties: </p>
              <ul>
                <li>
                  {' '}
                  We may collect data from the merchants who advertise their
                  products or services on our site, for example about your
                  redemption of any voucher that you buy from us or if we
                  provide delivery services to the merchant whereby we post the
                  products to you on behalf of the merchant;{' '}
                </li>

                <li>
                  {' '}
                  We work closely with third parties providing services to us
                  (including, for example, business partners, sub-contractors
                  providing technical, payment and delivery services related to
                  the goods or services we provide to you, advertising networks,
                  social networking platforms, analytics providers, search
                  information providers) and we may also receive information
                  about you from them;{' '}
                </li>

                <li>
                  If you enter sweepstakes, special offers or contests sponsored
                  by us or one of our business partners including email
                  addresses from publishers who work through the following
                  agencies - We Breathe Media, eGentic and Lead365. These
                  publishers include but are not limited to the following:
                </li>

                <ul>
                  <li>Aldanti International Network</li>

                  <li>All Groove</li>

                  <li>Affiliads</li>

                  <li>Dalma Media</li>

                  <li>Massive Ads Digital Limited (Datastars)</li>

                  <li>Submission Technology Limited</li>

                  <li>My Offers / PDV</li>

                  <li>Marketing Punch / Offerx</li>

                  <li>Luna Park Media</li>
                </ul>

                <li>
                  {' '}
                  In some circumstances related to a particular transaction, we
                  may obtain data from public databases or credit reference
                  agencies; and{' '}
                </li>

                <li>
                  {' '}
                  We may combine information that we collect about you from
                  other sources with the information that you give to us
                  directly. We may use this combined information as described in
                  this policy;{' '}
                </li>
              </ul>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                C. How do we use personal data, and what is our justification
                for doing so?
              </h3>
              <p>
                We use your personal data for a variety of purposes related to
                the products and services that we provide.{' '}
              </p>
              <table style={{ padding: '5px' }} width="100%">
                <tr>
                  <td colSpan="2">
                    <p>
                      <i>We collect your data for the reasons listed below</i>
                    </p>

                    <p>
                      <i>
                        The provision of products and services offered to you:
                      </i>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td width="70%">
                    <ul>
                      <li>
                        to provide you with the information, products and
                        services (including vouchers) that you request or
                        purchase from us (i.e. to complete certain tasks,
                        processes or orders on our website or within our apps,
                        take payment online (where applicable) and deliver your
                        products or services), and to communicate with you
                        regarding those products and services (including
                        vouchers) that you purchase from us and respond to your
                        questions and comments; and
                      </li>

                      <li>
                        to measure customer satisfaction and provide customer
                        service (including liaising with merchants to
                        troubleshoot in connection with your purchases or your
                        requests for services).
                      </li>
                    </ul>
                  </td>

                  <td width="30%">
                    <p>
                      We use your data in this way either because we have a
                      contract with you (for example, if you purchase a voucher
                      from our site, a contract to provide that voucher to you)
                      or because it is in our legitimate interests to do so (for
                      example, it is in our interests to measure customer
                      satisfaction and 'troubleshoot' customer issues) but we
                      will always ensure that your rights are protected.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <p>
                      <i>
                        For advertising and marketing purposes, including to
                        measure how effective our marketing is:
                      </i>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td width="70%">
                    <ul>
                      <li>
                        to contact you with daily or weekly emails to let you
                        know about products and services we offer (and that the
                        merchants who advertise on our sites offer) that we
                        think you might be interested in based on your
                        subscribed location and other preferences on your
                        account, we may also combine this with information we
                        collect from other sources to ensure we are only telling
                        you about products and services which would interest
                        you;
                      </li>

                      <li>
                        to measure the effectiveness of our marketing campaigns
                        and our advertising (i.e. so that we can tell what
                        interests our customers and what doesn't);
                      </li>

                      <li>
                        for information on how we use cookies, please see our
                        cookie policy;
                      </li>
                    </ul>
                  </td>

                  <td width="30%">
                    <p style={{ textAlign: 'justify' }}>
                      We rely on your consent to contact you directly about the
                      products and services on offer on our site (for example,
                      we will send you Daily Deals emails based on your
                      consent). In other scenarios, we will rely on our
                      legitimate interests as a business, always ensuring that
                      your rights are protected. If you have agreed to receive
                      marketing, you may always opt out at a later date. You
                      have the right to stop us from contacting you for
                      marketing purposes or giving your data to other members of
                      our group. If you no longer wish to be contacted for
                      marketing purpose, then please visit our email preference
                      centre, a link to our email preference centre is included
                      in the footer of every email that we send you.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <p>
                      <i>For administrative and internal business purposes:</i>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td width="70%">
                    <ul>
                      <li>
                        to ensure that content on our site is presented in the
                        most effective and relevant manner for you and for your
                        device and to tailor the site&#39;s experience and
                        content based on the way that you use the site;
                      </li>

                      <li>
                        to administer our site and for internal operations,
                        including troubleshooting, data analysis, testing,
                        research, statistical and survey purposes;
                      </li>

                      <li>
                        for our internal business purposes, such as analysing
                        and managing our businesses, audits, developing new
                        products or services, enhancing our site, improving our
                        services and products and identifying usage trends. For
                        these purposes, your personal data will be aggregated
                        and looked at on a statistical basis;
                      </li>
                    </ul>
                  </td>

                  <td width="30%">
                    <p>
                      It is in our legitimate interests as a business to use
                      your data in this way. For example, we have a clear
                      interest in ensuring that our site works properly and that
                      our products and services are high quality and efficient.
                      We will always ensure that your rights are protected.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <p>
                      <i>For security and legal and compliance purposes:</i>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td width="70%">
                    <ul>
                      <li>
                        as part of our efforts to keep our site safe and secure;
                      </li>

                      <li>
                        to detect or prevent fraud or other illegal activity;
                      </li>

                      <li>
                        as we believe to be necessary or appropriate in each
                        case in order to comply with laws or legal process
                        (including laws or legal process in other countries);
                      </li>

                      <li>
                        to protect our rights or property (or the rights or
                        property of others) and to enforce our rights and pursue
                        available remedies;
                      </li>
                    </ul>
                  </td>

                  <td width="30%">
                    <p>
                      In some cases, we will need to use your personal data to
                      fulfil a legal obligation (for example, if we receive a
                      legitimate request from law enforcement agencies), and in
                      other cases (such as the detection of fraud or ensuring
                      the security of the site, for example by verifying the
                      identification of merchants before permitting access to
                      our premises) we will rely on our legitimate interests as
                      a business to use your data in this way.
                    </p>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <p>
                      <i>International transfer of your data:</i>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td width="70%">
                    <ul>
                      <li>
                        some of the processes involved in our use of your
                        personal data may require your data to be stored or
                        processed in countries outside of Europe.
                      </li>
                    </ul>
                  </td>

                  <td width="30%">
                    <p>
                      Whenever we send (or permit a third party to send) your
                      personal data outside of Europe, we will make sure that we
                      take steps necessary to protect your data as required by
                      applicable laws. For example, we may implement specific
                      contract terms or we may rely on service providers who
                      adhere to certain compliance programmes overseas, or we
                      may select service providers based in countries with
                      strong local laws to protect your personal data.
                    </p>
                  </td>
                </tr>
              </table>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                D. How do we share your personal data?
              </h3>
              <p>
                We will never sell your personal data to third parties. Your
                personal data is only shared with trusted third parties for a
                specific reason, such as to facilitate payments, to enable the
                sending of emails to customers and suppliers, or to manage our
                sales process or the provision of customer services to you. The
                various ways in which we will share your data are set out below:
              </p>
              <ol type="i">
                <li>
                  <i>Sharing data within our group:</i>
                </li>

                <p>
                  We are part of a group of companies that share various
                  operations and business processes. We may share your personal
                  data with any member of our group in order to fulfil our
                  contractual obligations to you, or because it is in our
                  legitimate interests to do so (for example, our legal function
                  is provided by another group company which means that your
                  personal data may be shared with our lawyers who are based in
                  another group company).
                </p>

                <p>
                  We may also disclose personal data to other companies in our
                  group (which includes LivingSocial Europe Limited) and may use
                  and share with those companies any information we learn from
                  your interactions with us and from other companies in our
                  group. Subject to acquiring your consent, you may be contacted
                  by other companies in our corporate group by email, telephone,
                  SMS and/or post with information and offers within the group.
                </p>

                <li>
                  <i>
                    Sharing with merchants of the products and services we
                    offer:
                  </i>
                </li>

                <p>
                  We host offers on behalf of third party merchants on our
                  website and, as such, we will share some information about you
                  with the merchant when you purchase a voucher from us to be
                  redeemed against the product or service offered by them. In
                  almost all cases, this is because such sharing is necessary in
                  connection with our performance of our contract with you, and
                  in some other cases it may be in our legitimate interests to
                  do this since this enables us and the merchants to provide you
                  with competitive offers for a range of products and services
                  and to prevent fraud. This also helps improve the services we
                  and the merchants offer to you.
                </p>

                <p>
                  When you redeem a voucher with a third party merchant, you
                  will be entering into a separate contract with that third
                  party merchant and, in these cases, your personal data will be
                  collected by the third party merchant and not by us. You
                  should also read the third party merchant's privacy policy to
                  make sure that you understand how they may use your data. In
                  some instances the third party merchant may share some of your
                  personal data with us for example to evidence that your order
                  has been placed or if they ask us to provide redemption or
                  delivery services for them. To ensure you get the best serice,
                  if a third party merchant informs us that they are no longer
                  able to fulfil your order after you have redeemed your
                  voucher, we might share your information with an alternate
                  trusted merchant who is able to provide you with the same or
                  similar goods or services.
                </p>

                <li>
                  <i>Sharing with organisations providing services to us:</i>
                </li>

                <p>
                  We engage various third parties to provide services to us for
                  specific functions, and this will often mean that we need to
                  share your personal data with them (it is in our legitimate
                  interests to do so, since we may not have the capabilities to
                  provide these services ourselves). In each case, we will
                  ensure that these third parties are only allowed to use your
                  personal data in order to provide the relevant services to us.
                  We will always make sure that we use organisations that we
                  trust to look after your personal data appropriately and as
                  required by applicable laws.
                </p>

                <p>
                  Some examples of these third parties will include: credit card
                  processing services, order fulfilment, analytics, website
                  management, information technology and related infrastructure
                  provision, customer service, WhatsApp messaging supplier,
                  auditing, and other similar services.
                </p>

                <p>
                  In some cases, we may use a third party payment service such
                  as PayPal to process purchases made through the site. In these
                  cases, your personal data will be collected by the payment
                  provider on our behalf, and not by us. You should also read
                  the payment provider's privacy policy to make sure that you
                  understand how they may use your data.
                </p>

                <li>
                  <i>
                    Sharing data with online advertising related service
                    providers:
                  </i>
                </li>

                <p>
                  We may from time to time work with online advertisers, social
                  media platforms and advertising networks to deliver, tailor
                  and measure advertising to you and others both on the site and
                  elsewhere on the internet (including on social media platforms
                  such as Facebook, Snapchat, Tiktok, Pinterest and Twitter). We
                  also use various tools and products which involve the sharing
                  of data with Google (
                  <a href="https://policies.google.com/privacy?hl=en-GB">
                    https://policies.google.com/privacy?hl=en-GB
                  </a>
                  ), Vonage (
                  <a href="https://www.vonage.com/legal/privacy-policy/">
                    https://www.vonage.com/legal/privacy-policy/
                  </a>
                  ) Facebook (
                  <a href="https://www.facebook.com/policy.php">
                    https://www.facebook.com/policy.php
                  </a>
                  ), Snapchat (
                  <a href="https://www.snap.com/en-GB/privacy/privacy-policy">
                    https://www.snap.com/en-GB/privacy/privacy-policy
                  </a>
                  ), Appsflyer (
                  <a href="https://www.appsflyer.com/legal/services-privacy-policy/">
                    https://www.appsflyer.com/legal/services-privacy-policy/
                  </a>
                  ), Acxiom (
                  <a href="https://www.acxiom.co.uk/about-acxiom/privacy/uk-privacy-policy/">
                    https://www.acxiom.co.uk/about-acxiom/privacy/uk-privacy-policy/
                  </a>
                  ), Lotame (
                  <a href="https://www.lotame.com/privacy-and-data-protection/">
                    https://www.lotame.com/privacy-and-data-protection/
                  </a>
                  ), OnAudience (
                  <a href="https://www.onaudience.com/privacy-policy">
                    https://www.onaudience.com/privacy-policy/
                  </a>
                  ), Criteo (
                  <a href="https://www.criteo.com/privacy/">
                    https://www.criteo.com/privacy/
                  </a>
                  ), Pinterest (
                  <a href="https://policy.pinterest.com/ms/privacy-policy-2016">
                    https://policy.pinterest.com/ms/privacy-policy-2016
                  </a>
                  ), TikTok (
                  <a href="https://www.tiktok.com/legal/page/eea/privacy-policy/en">
                    https://www.tiktok.com/legal/page/eea/privacy-policy/en
                  </a>
                  ) to deliver, measure and tailor our online advertising.
                </p>

                <p>
                  The personal data that we allow to be shared with these third
                  parties includes online identifiers such as IP address, data
                  from cookies or similar technologies, or other device related
                  information.
                </p>

                <p>
                  Similarly, we may share certain online identifiers with
                  analytics and search engine providers that assist us in the
                  improvement and optimisation of our site.
                </p>

                <p>
                  You can choose not to allow your data to be used to deliver
                  targeted advertising by changing your settings within social
                  media platforms, or as described in the third party privacy
                  policies set out above.
                </p>

                <li>
                  <i>
                    Sharing data in connection with changes to our group
                    structure or the ownership of our business:
                  </i>
                </li>

                <p>
                  If we sell or propose to sell our business or part of it, we
                  may need to disclose your personal data to prospective buyers.
                </p>

                <p>
                  Similarly, if there are changes to our group structure or if
                  our ownership changes, we may need to disclose your personal
                  data to the new owners or operators of this site or any of our
                  apps as part of that process.
                </p>

                <li>
                  <i>Sharing data to comply with laws:</i>
                </li>

                <p>
                  There may be scenarios where we are subject to a legal
                  obligation to disclose or share your personal data, such as
                  with law enforcement agencies or public authorities in order
                  to prevent or detect crime.
                </p>
              </ol>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                3. Security and retention of your personal data
              </h2>
              <ol type="i">
                <li>
                  <i>Security of your personal data:</i>
                </li>

                <p>
                  We take the security of your data very seriously and have put
                  physical, technical and administrative strategies, controls
                  and measures in place to help protect your personal data from
                  unauthorised access, use of disclosure as required by law and
                  in accordance with accepted good industry practice.
                </p>

                <p>
                  To help us keep your data safe please choose a strong, secure
                  password and keep your password confidential and avoid sharing
                  it with anyone.{' '}
                </p>

                <li>
                  <i>Retention of your personal data:</i>
                </li>

                <p>
                  We keep your personal information for as long as is necessary
                  for the relevant purposes described in this privacy policy, as
                  may be required by law such as for tax and accounting
                  purposes, or as otherwise communicated to you. This means that
                  the retention periods will vary according to the type of the
                  data and the reason that we have the data in the first place.
                </p>
              </ol>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                4. Your rights{' '}
              </h2>
              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <ol type="a">
                <li>
                  to ask us not to use your personal data for direct marketing
                  purposes;
                </li>

                <li>
                  The right to access &minus; you have the right to ask to see
                  what personal data we hold about you and to find out about the
                  way that we process the data (and in some circumstances, you
                  can ask us to provide a copy to a third party). We may charge
                  you a small fee for this service;
                </li>

                <li>
                  The right to rectification &minus; You have the right to ask
                  us to correct or update any personal data which is inaccurate.
                  You also have the right to request that we complete
                  information you believe is incomplete;
                </li>

                <li>
                  The right to erasure &minus; you have the right to ask for
                  personal data to be deleted in some (but not all)
                  circumstances where there is no good reason for us to continue
                  to process it;
                </li>

                <li>
                  The right to restrict and object to processing &minus; you
                  have the right to ask us to temporarily stop using your data
                  if you don't believe that we have a right to use it, or to
                  stop us from using your personal data where there is no good
                  reason for us to continue to use it and not to be subject to
                  decisions made solely on the basis of 'automated processing'
                  (i.e. the right not to be subject to decisions made solely by
                  algorithms or computers without input from a human) in certain
                  circumstances; and{' '}
                </li>

                <li>
                  The right to data portability - You have the right to request
                  that we transfer the data we have collected to another
                  organisation, or directly to you, under certain conditions.
                </li>
              </ol>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                5. Cookie Policy
              </h2>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                What are cookies?
              </h3>
              <p>
                In addition to personal information you provide, we may also
                collect information about you automatically when you visit the
                Site or access content in our electronic communications with
                you.
              </p>
              <p>
                The collection of information may involve the use of cookies and
                web beacons. A web beacon is a small graphic image placed on a
                web page that is designed to allow us to monitor traffic on the
                Site or within electronic communications. A cookie is a small
                text file that is placed on your computer's hard drive by your
                browser. Cookies allow us to identify your computer and find out
                details about your visit and browsing habits so that we can
                offer you a more personalised experience and manage, monitor and
                improve services.
              </p>
              <p>
                Most internet browsers accept cookies automatically, but usually
                you can alter the settings of your browser to erase cookies or
                prevent automatic acceptance if you prefer. If you choose not to
                receive our cookies, you may not be able to use some of the core
                features of the Site.
              </p>
              <p>
                The information collected by cookies and web beacons is not tied
                to your contact details (such as your name or email address), it
                only includes general information about your computer settings,
                your connection to the internet e.g. operating system and
                platform, IP address, your browsing patterns and timings of
                browsing on the Site and geographical location.
              </p>
              <p>
                You can learn more about cookies and how to reject cookies using
                different types of browser by visiting{' '}
                <a href="http://www.allaboutcookies.org">
                  http://www.allaboutcookies.org
                </a>
                .
              </p>

              <p>
                Separate tools are also available for managing local shared
                objects or 'Flash cookies' from the Adobe website. These cookies
                may be used to manage the effective delivery of services using
                video or other flash related content.
              </p>
              <p>
                While you do not need to allow your browser to accept cookies in
                order to browse much of our web site or to access many of our
                services, you must have cookies enabled if you wish to undertake
                certain services on our website or access any areas reserved for
                registered users.
              </p>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                How do we use cookies
              </h3>
              <p>
                Cookies help us provide you with a better website experience, by
                enabling us to monitor which pages you find useful and which you
                do not. A cookie in no way gives us access to your computer or
                any information about you, other than the data you choose to
                share with us.
              </p>
              <p>
                {' '}
                We use cookies in a range of ways to improve your experience on
                our website including:{' '}
              </p>
              <ul>
                <li>
                  to enable us to simplify the signing in process for registered
                  users;
                </li>

                <li>
                  to help ensure the security and authenticity of registered
                  users; and
                </li>

                <li>
                  to provide the mechanisms for online services and to enable
                  traffic monitoring. This helps us analyse data about web page
                  traffic and improve our website in order to tailor it to
                  customer needs.
                </li>
              </ul>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                What type of cookies do we use?
              </h3>
              <ul>
                <li>
                  Strictly necessary cookies. These are cookies that are
                  required for the operation of our website. They include, for
                  example, cookies that enable you to log into secure areas of
                  our website.
                </li>

                <li>
                  Analytical/performance cookies. They allow us to recognise and
                  count the number of visitors and to see how visitors move
                  around our website when they are using it. This helps us to
                  improve the way the website works, for example, by ensuring
                  that users are finding what they are looking for easily.
                </li>

                <li>
                  Functionality cookies. These are used to recognise you when
                  you return to our website. This enables us to personalise our
                  content for you, greet you by name and remember your
                  preferences (for example, your choice of location).
                </li>

                <li>
                  Targeting and advertising cookies from us and 3rd paries.
                </li>

                <ul>
                  <li>
                    These cookies record your visit to our website, the pages
                    you have visited and the links you have followed and
                    information about your browser, device and your IP address.
                    We will use this information to make our website more
                    relevant to your interests. We may also share this
                    information with third parties to make advertising more
                    relevant to you. We may also share online data collected
                    through cookies with our advertising partners. This means
                    that when you visit another website, you may be shown
                    advertising based on your browsing patterns on our website.
                    Display advertising on the Site is provided by our
                    advertising partners. Our advertising partners will serve
                    advertisements that they believe are most likely to be of
                    interest to you, based on information about your visit to
                    the Site and other websites (this information does not
                    include your name, street address, email address or
                    telephone number).{' '}
                  </li>

                  <li>
                    One of the third party cookies on our site is provided by
                    Criteo. The purpose of Criteo's service is to find visitors
                    who also visit the websites of other companies on the Criteo
                    network and serve these visitors personalized ads or where
                    we or the other company has consent from the visitor, send
                    personalized emails. Criteo uses cookies to single out
                    visitors and then personalize ads based on their browsing
                    experience. To learn more or opt out of Criteo{' '}
                    <a href="https://www.criteo.com/privacy/">click here.</a>
                  </li>

                  <li>
                    For more information about this type of online behavioural
                    advertising please visit{' '}
                    <a href="https://www.youronlinechoices.com/uk/">
                      www.youronlinechoices.com/uk/.
                    </a>
                  </li>
                </ul>
              </ul>
              <h3 className="main-section" style={{ color: '#5A266A' }}>
                How to manage cookies
              </h3>
              <p style={{ textAlign: 'justify' }}>
                You block cookies by activating the setting on your browser that
                allows you to refuse the setting of all or some cookies.
                However, if you use your browser settings to block all cookies
                (including essential cookies) you may not be able to access all
                or parts of our site.{' '}
              </p>
              <p>
                Visit{' '}
                <a href="https://optout.networkadvertising.org/#!/">
                  {' '}
                  http://optout.networkadvertising.org/#!/
                </a>{' '}
                to find out more.
              </p>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                6. Privacy policies of other websites
              </h2>
              <p>
                Our website contains links to other websites. This privacy
                policy applies only to our website, so if you click on a link to
                another website, you should read their privacy policy.
              </p>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                7. Changes to our privacy policy
              </h2>
              <p style={{ textAlign: 'justify' }}>
                Any changes we may make to our privacy policy in the future will
                be posted on this site. We may also email you with any changes
                if we have your email address. Please check back frequently to
                see any updates or changes to our privacy policy.
              </p>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                8. How to contact us
              </h2>
              <p style={{ textAlign: 'justify' }}>
                If you would like to unsubscribe from all 5pm emails; close your
                account; or exercise any of the rights listed above, or if you
                have any queries or concerns about the way that we use your
                personal data (or any questions about this privacy policy), you
                can contact us by emailing{' '}
                <a href="mailto:privacy@5pm.co.uk">privacy@5pm.co.uk</a>.
                Alternatively, if you wish to manage your subscription
                (including the frequency of our updates) please visit our email
                preference centre, a link to our email preference centre is
                included in the footer of every email that we send to you.
              </p>
              <h2 className="main-section" style={{ color: '#5A266A' }}>
                9. How to contact the appropriate authority
              </h2>
              <p>
                You also have the right to complain about our use of your
                personal data. You can contact the Information Commissioner's
                Office via their website:{' '}
                <a href="https://ico.org.uk/concerns/">
                  https://ico.org.uk/concerns/
                </a>{' '}
                or by calling 0303 123 1113.
              </p>
            </div>
          </TileBody>
        </Tile>
      </div>
      <style jsx>
        {`
          .container {
            padding: 30px 0;
          }
          .position-relative {
            font-size: 16px;
          }

          // move the footer at the bottom of the page
          :global(body, html) {
            min-height: 100%;
            height: 100%;
          }
          :global(body > div) {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          :global(#main-view) {
            height: 100%;
          }
        `}
      </style>
    </>
  );
};

PrivacyPolicy.title = 'Privacy Policy';
PrivacyPolicy.layout = DashboardLayout;
PrivacyPolicy.countdown = false;

export const getServerSideProps = wrapper.getServerSideProps(
  async ({ req, store }) => {
    await store.dispatch(getServerSideEssentials(req.url)).catch((error) => {
      console.log(`getServerSideEssentials error: ${error}`);
    });
  },
);

export default PrivacyPolicy;
